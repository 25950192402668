import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Observable, Subject, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureValue } from 'src/app/models/FeatureValue';
import { environment } from '../../environments/environment';
import { CustomHeader, Parts } from '../enums';
import { ParseEntity } from '../helpers/utilities';
import { AuthenticationToken, Community, ISortInfo, PaginationInfo, SearchInfo, Structure, User } from '../models';
import { Feedback } from '../models/feedback';
import { SmartHttpModuleService, UrlBuilderService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class DataleanDataProviderService {
  private readonly ORGANIZATION_PREFIX_URL_FIELD_KEY = '?organizationPrefix=';
  private readonly ORGANIZATION_URL_FIELD_KEY = '?organizationUUID=';

  constructor(private smartHttpModule: SmartHttpModuleService) {}

  private toCommunity(resultItem: any): Community {
    const { uuid, name, category, logo, listAdmin, active, values } = resultItem;
    const result = Object.assign(new Community(), { uuid, name, category, logo, listAdmin, active, values });
    return result;
  }

  mapStructures(result: any): Structure[] {
    return result ? result.map((data) => new Structure(data)) : [];
  }

  mapStructure(result: Partial<Structure>): Structure {
    // console.log('mapStructure', result);
    return new Structure(result ?? {});
  }

  public hasJsonStructure(str) {
    if (typeof str !== 'string') {
      return false;
    }
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]' || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  activateUser(code): Observable<any> {
    const requestUrl = new UrlBuilderService(
      environment.applicationUsersUrl + 'user-activate-via-code' + '?organizationUUID=' + environment.organizationUUID,
    )
      .withQueryParam('code', code)
      .build();

    return this.smartHttpModule.get(requestUrl);
  }

  assignAdminToCommunity(adminUUID, uuidList): Observable<any> {
    const requestUrl = new UrlBuilderService(
      environment.usersUrl + adminUUID + '/assignAdminRoleToCommunity' + '?organizationUUID=' + environment.organizationUUID,
    ).build();

    return this.smartHttpModule.post(requestUrl, uuidList);
  }

  changePasswordRequest(resetPasswordToken, password, url?): Observable<any> {
    const requestUrl = new UrlBuilderService(url ? url + 'change-password' : environment.usersUrl + 'change-password')
      .withQueryParam('organizationUUID', environment.organizationUUID)
      .withQueryParam('newPassword', password)
      .build();

    return this.smartHttpModule.post(requestUrl, undefined, { headers: { authorization: resetPasswordToken } });
  }

  login(username: string, password: string): Observable<AuthenticationToken> {
    const data = {
      username,
      password,
    };
    const requestUrl = new UrlBuilderService(
      environment.authenticationUrl + this.ORGANIZATION_PREFIX_URL_FIELD_KEY + environment.organizationPrefix,
    ).build();

    return this.smartHttpModule.post(requestUrl, data).pipe(map((d) => this.mapAuthenticationData(d)));
  }

  getUserWithUUID(userUUID: string): Observable<User> {
    const requestUrl = new UrlBuilderService(environment.usersUrl + userUUID + '?organizationUUID=' + environment.organizationUUID)
      .withParts([Parts.ROLES])
      .build();

    return this.smartHttpModule.get(requestUrl).pipe(map((data) => this.mapUserData(data)));
  }

  getAvailableCode(code: string): Observable<void> {
    const requestUrl = new UrlBuilderService(
      environment.communityUrl + 'generateCode' + this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID,
    )
      .withAdditionalParameter({ code })
      .build();

    return this.smartHttpModule.post(requestUrl, '', {
      responseType: 'text',
    });
  }

  getUpdateLastDates(additionalParams: any, entityUUID?: string): Observable<any> {
    let feedbackUrl = environment.applicationUsersUrl + 'feedbacks';
    if (entityUUID) {
      feedbackUrl = environment.applicationUsersUrl + entityUUID + '/feedback';
    }
    return this.getDataForList<Feedback>(feedbackUrl, additionalParams, [Parts.EMPTY]);
  }

  getLastOrderForCommunity(communityUUID: string): Observable<any> {
    const subject = new Subject();
    const requestUrl = new UrlBuilderService(environment.orderUrl + '?organizationUUID=' + environment.organizationUUID)
      .withParts([Parts.EMPTY])
      .withQueryParam('orderCommunityUUID', communityUUID)
      .build();

    this.smartHttpModule.get(requestUrl).subscribe((data) => {
      data.result = data.result.sort((a, b) =>
        a.incrementalNumber > b.incrementalNumber ? -1 : a.incrementalNumber < b.incrementalNumber ? 1 : 0,
      );
      subject.next(data.result[0]);
    });
    return subject.asObservable();
  }

  getUserWithUUIDPartsAll(userUUID: string): Observable<User> {
    const requestUrl = new UrlBuilderService(environment.usersUrl + userUUID + '?organizationUUID=' + environment.organizationUUID)
      .withParts([Parts.ALL])
      .build();

    return this.smartHttpModule.get(requestUrl).pipe(map((data) => this.mapUserData(data)));
  }

  exportXml(mapContainerUUID: string, cateogryUUID: string, url: string, communityUUID?: string): Observable<any> {
    const requestUrl = new UrlBuilderService(url + '?organizationUUID=' + environment.organizationUUID)
      .withQueryParam('UUIDType', 'MAP_CONTAINER')
      .withQueryParam('featureValueUUID', cateogryUUID)
      .withQueryParam('uuid', mapContainerUUID);
    if (communityUUID) requestUrl.withQueryParam('communityUUID', communityUUID);
    return this.smartHttpModule.get(requestUrl.build(), '');
  }
  exportECP(url: string, type: string, line: string, email: string): Observable<any> {
    return this.smartHttpModule.post(
      url + type + '?organizationUUID=' + environment.organizationUUID,
      { line, email },
      { responseType: 'text' },
    );
  }
  exportCatalogue(url: string, brand: string, email: string, languageOne: string, languageTwo: string): Observable<any> {
    return this.smartHttpModule.post(
      `${url}?organizationUUID=${environment.organizationUUID}&brand=${brand}&email=${email}&languageOne=${languageOne}&languageTwo=${languageTwo}&parts=export,featureValueList,relatedProductsList,structureFields,related,values&downloadAssets=true`,
      undefined,
    );
  }
  exportStructuresWithMailAndStructureUuid(email: string, structUuid: string, url: string, additionalParams?: object): Observable<User> {
    const requestUrl = new UrlBuilderService(url + '?organizationUUID=' + environment.organizationUUID)
      .withParts([Parts.EXPORT, Parts.FEATURE_VALUE_LIST, Parts.RELATED_PRODUCTS_LIST, Parts.STRUCTURE_FIELDS, Parts.RELATED, Parts.VALUES])
      .withQueryParam('recipientEmail', email);
    if (structUuid) requestUrl.withQueryParam('structureUUID', structUuid);
    if (additionalParams) {
      Object.keys(additionalParams).forEach((key) => {
        requestUrl.withQueryParam(key, additionalParams[key]);
      });
    }

    return this.smartHttpModule.get(requestUrl.build(), { responseType: 'text' });
  }

  getAllEntities<T>(endpoint: string, parts: Parts[], additionalParams?) {
    return this.getDataForList<T>(endpoint, additionalParams, parts) as Observable<{ result: T[]; paginationInfo: PageEvent }>;
  }

  getDataForList<T>(
    endpoint: string,
    additionalParams: any,
    parts: Parts[],
    searchInfo?: SearchInfo,
    paginationInfo?: PaginationInfo,
    sortInfo?: ISortInfo,
  ): Observable<{
    result: T[] | { [key: string]: T };
    paginationInfo?: PageEvent;
  }> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const requestUrlBuilder = new UrlBuilderService(endpoint + organization).withParts(parts);

    if (additionalParams) {
      const key = Object.keys(additionalParams).find((k) => k.includes('ommunityUUID'));
      if (key) {
        const { communityEntityUUID, ...params } = additionalParams;
        additionalParams = params;
      }
      for (const filter of Object.keys(additionalParams)) {
        if (additionalParams[filter] !== undefined) {
          // console.log('adding filter for %s = %s', filter, additionalParams[filter]);
          requestUrlBuilder.withQueryParam(filter, additionalParams[filter]);
        }
      }
    }

    const options = {};
    if (paginationInfo) {
      requestUrlBuilder.withPaginationInfo(paginationInfo);
      options['observe'] = 'response';
    }

    if (sortInfo && sortInfo.isActive()) {
      requestUrlBuilder.withSortInfo(sortInfo);
    }

    if (searchInfo?.query && searchInfo?.searchFields) {
      requestUrlBuilder.withSearchFilter(searchInfo);
    }

    return this.smartHttpModule.get(requestUrlBuilder.build(), options).pipe(
      map((data: HttpResponse<{ result: T[] }> | T[] | { [key: string]: T }) => {
        let result = data as T[];
        let paginationInfoResult = { pageIndex: 0, pageSize: 25, length: result.length } as PageEvent;
        if (data instanceof HttpResponse) {
          result = (data?.body?.result ?? data?.body ?? []) as T[];
        }
        if ('result' in data) {
          result = (data.result ?? []) as T[];
        }
        if (!!paginationInfo && data instanceof HttpResponse && !!data?.headers) {
          paginationInfoResult = {
            pageIndex: parseInt(data.headers.get(CustomHeader.pageIndex) ?? '1') - 1,
            pageSize: parseInt(data.headers.get(CustomHeader.pageSize) ?? '25'),
            length: parseInt(data.headers.get(CustomHeader.totalCount) ?? '0') ?? result.length,
          };
        }
        return {
          result,
          paginationInfo: paginationInfoResult,
        };
      }),
    );
  }

  patch(endpoint: string, dataToUpdate: any, parts: Parts[], additionalParams = {}) {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const lastCharSlash = endpoint.slice(-1) === '/';
    if (!lastCharSlash) {
      endpoint += '/';
    }
    const requestUrl = new UrlBuilderService(endpoint + organization).withAdditionalParameter(additionalParams).withParts(parts).build();
    return this.smartHttpModule.patch(requestUrl, dataToUpdate);
  }

  patchEntity(endpoint: string, dataToUpdate: any, entityUUID: string, parts: Parts[], additionalParams = {}) {
    const lastCharSlash = endpoint.slice(-1) === '/';
    if (!lastCharSlash) {
      endpoint += '/';
    }
    const url = endpoint + entityUUID;
    return this.patch(url, dataToUpdate, parts, additionalParams);
  }

  updateEntity(
    endpoint: string,
    updatedEntity: any,
    parts: Parts[],
    extraPath?: string,
    additionalParams = {},
    stringify = false,
    options?,
    isPatch?: boolean,
  ): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    if (updatedEntity?.uuid) {
      delete updatedEntity['_id'];
      const lastCharSlash = endpoint.slice(-1) === '/';
      if (!lastCharSlash) {
        endpoint += '/';
      }
      let url = endpoint + updatedEntity.uuid;
      if (extraPath) {
        url += '/' + extraPath;
      }
      const requestUrl = new UrlBuilderService(url + organization).withAdditionalParameter(additionalParams).withParts(parts).build();
      if (!isPatch) {
        return this.smartHttpModule.put(requestUrl, stringify ? updatedEntity : JSON.stringify(updatedEntity), options);
      } else {
        return this.smartHttpModule.patch(requestUrl, stringify ? updatedEntity : JSON.stringify(updatedEntity), options);
      }
    } else {
      return of(undefined);
    }
  }

  /* REMEMBER TO REMOVE _id FROM updateFormData NESTED ENTITY OBJECT */
  updateEntityWithFormData(
    endpoint: string,
    updateFormData: any,
    entityUUID,
    parts: Parts[],
    extraPath?: string,
    additionalParams = {},
    stringify = false,
    options?,
  ): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    if (entityUUID) {
      const lastCharSlash = endpoint.slice(-1) === '/';
      if (!lastCharSlash) {
        endpoint += '/';
      }
      let url = endpoint + entityUUID;
      if (extraPath) {
        url += '/' + extraPath;
      }
      const requestUrl = new UrlBuilderService(url + organization).withAdditionalParameter(additionalParams).withParts(parts).build();
      return this.smartHttpModule.put(requestUrl, stringify ? updateFormData : JSON.stringify(updateFormData), options);
    } else {
      return of(undefined);
    }
  }

  createEntity(endpoint: string, newEntity: any, parts: Parts[], additionalParameter = {}, stringify = false, options?): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const requestUrl = new UrlBuilderService(endpoint + organization).withParts(parts).withAdditionalParameter(additionalParameter).build();
    return this.smartHttpModule.post(requestUrl, stringify ? newEntity : JSON.stringify(newEntity), options);
  }

  clone(uuidList: any, communityUUID: any, parts: Parts[], endpoint): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const requestUrl = new UrlBuilderService(endpoint + 'clone' + organization)
      .withParts(parts)
      .withAdditionalParameter({ communityUUID })
      .build();
    return this.smartHttpModule.post(requestUrl, uuidList);
  }

  multipleUpdate(uuidList: any, communityUUID: any, parts: Parts[], endpoint: string): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    let requestUrl;
    if (communityUUID || communityUUID === '')
      requestUrl = new UrlBuilderService(endpoint + 'multipleUpdate' + organization)
        .withParts(parts)
        .withAdditionalParameter({ communityUUID })
        .build();
    else requestUrl = new UrlBuilderService(endpoint + 'multipleUpdate' + organization).withParts(parts).build();
    return this.smartHttpModule.post(requestUrl, uuidList);
  }

  createCommunityWithoutUUID(endpoint: string, newEntity: any, parts: Parts[]): Observable<any> {
    delete newEntity.uuid;
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    if (newEntity) {
      const requestUrl = new UrlBuilderService(endpoint + organization).withParts(parts).build();
      return this.smartHttpModule.post(requestUrl, JSON.stringify(newEntity)).pipe(map((data) => this.toCommunity(data)));
    } else {
      throwError('create failed');
    }
  }

  deleteEntity(endpoint: string, entityUUID: string): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const lastCharSlash = endpoint.slice(-1) === '/';
    if (!lastCharSlash) {
      endpoint += '/';
    }
    const requestUrl = new UrlBuilderService(endpoint + entityUUID + organization).build();
    return this.smartHttpModule.delete(requestUrl);
  }

  getEntity(endpoint: string, entityUUID: string, parts: Parts[], additionalParams?: any): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const lastCharSlash = endpoint.slice(-1) === '/';
    if (!lastCharSlash) endpoint += '/';

    const requestUrlBuilder = new UrlBuilderService(endpoint + entityUUID + organization).withParts(parts);
    if (additionalParams) {
      for (const filter of Object.keys(additionalParams)) {
        if (additionalParams[filter] !== undefined) {
          // console.log('adding filter for %s = %s', filter, additionalParams[filter]);
          requestUrlBuilder.withQueryParam(filter, additionalParams[filter]);
        }
      }
    }
    // console.log('requestUrlBuilder', requestUrlBuilder.build());

    return this.smartHttpModule.get(requestUrlBuilder.build());
  }

  getUserToken(userUUID: string): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const requestUrl = new UrlBuilderService(environment.authenticationUrl + 'userToken' + organization)
      .withQueryParam('userUUID', userUUID)
      .withQueryParam('userType', 'APPLICATION_USER')
      .build();

    return this.smartHttpModule.get(requestUrl);
  }

  private mapUserData(responseData: any): User {
    const user = ParseEntity(new User(), responseData);
    user.id = user.uuid;
    // console.log('user', user);
    return user;
  }

  private mapAuthenticationData(responseData: any): AuthenticationToken {
    return new AuthenticationToken(responseData.token, responseData.expiryTokenTimestamp);
  }

  // private getUserUUIDQueryParam(): string {
  //   return this.userSettingService.getUserUUID() + encodeURIComponent('|') + 'none';
  // }

  public mapCommunities(result: any): Community[] {
    return result.map((data) => this.toCommunity(data));
  }

  public mapCommunity(result: any): Community {
    return this.toCommunity(result);
  }

  public mapFeatures(result: any): FeatureValue[] {
    return result ? result.map((data) => this.toFeature(data)) : [];
  }

  private toFeature(resultItem: Partial<FeatureValue>): FeatureValue {
    if (resultItem.name && resultItem.label) {
      return {
        uuid: resultItem.uuid,
        name: resultItem.name,
        label: resultItem.label,
        featureValues: resultItem.featureValues,
      };
    }
  }

  convertDate(dateString: string) {
    const date = new Date(dateString);
    const hr = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    return (
      this.fixDecimals(date.getDay()) +
      '/' +
      this.fixDecimals(date.getMonth()) +
      '/' +
      this.fixDecimals(date.getFullYear()) +
      ' @ ' +
      this.fixDecimals(hr) +
      ':' +
      this.fixDecimals(min) +
      ':' +
      this.fixDecimals(sec)
    );
  }

  fixDecimals(num: string | number) {
    return +num < 10 ? '0' + num : num;
  }

  importGroupFromFile(file: File, groupName: string, userField: string): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const requestUrl = new UrlBuilderService(environment.groupsUrl + 'import' + organization)
      .withQueryParam('groupName', groupName)
      .withQueryParam('userField', userField)
      .build();

    const formData = new FormData();
    formData.append('file', file);

    return this.smartHttpModule.post(requestUrl, formData);
  }

  importFromFile(
    endpoint: string,
    file: File,
    structureUUID: string,
    email: string,
    locale?: string,
    subStructureUUID?: string,
    communityUUID?: string,
  ): Observable<any> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const requestUrlBuilder = new UrlBuilderService(endpoint + 'import' + organization)
      .withQueryParam('structureUUID', structureUUID)
      .withQueryParam('recipientEmail', email);

    if (locale) {
      requestUrlBuilder.withQueryParam('locale', locale);
    }
    if (subStructureUUID) {
      requestUrlBuilder.withQueryParam('substructureUUID', subStructureUUID);
    }
    if (communityUUID) {
      requestUrlBuilder.withQueryParam('communityUUID', communityUUID);
    }
    const formData = new FormData();
    formData.append('file', file);

    return this.smartHttpModule.post(requestUrlBuilder.build(), formData);
  }

  updateUserFields(userUUID: string, body): Observable<User> {
    const organization = this.ORGANIZATION_URL_FIELD_KEY + environment.organizationUUID;
    const requestUrl = new UrlBuilderService(environment.usersUrl + userUUID + organization).build();

    return this.smartHttpModule.patch(requestUrl, body).pipe(map((data) => this.mapUserData(data)));
  }
}

export type getDataForList<T> =
  ReturnType<DataleanDataProviderService['getDataForList']> extends Observable<unknown>
    ? Observable<{
        result:
          | T[]
          | {
              [key: string]: T;
            };
        paginationInfo?: PageEvent;
      }>
    : never;

export type getAllEntities<T> =
  ReturnType<DataleanDataProviderService['getAllEntities']> extends Observable<unknown>
    ? Observable<{ result: T[]; paginationInfo: PageEvent }>
    : never;
